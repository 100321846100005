import React, { useEffect, useState } from 'react';
import { HiMenuAlt4, HiX } from 'react-icons/hi';
import { motion } from 'framer-motion';

import { images } from '../../constants';
import './Navbar.scss';

const Navbar = () => {
  const [toggle, setToggle] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleMenuToggle = () => {
    setToggle(prevToggle => !prevToggle);
  };

  useEffect(() => {
  }, [toggle]);

  return (
    <nav className="app__navbar">
      <div className="app__navbar-logo">
        <a href={`/`}>
          <img src={images.logo} alt="logo" />
        </a>
      </div>
      <ul className="app__navbar-links">
        <li className="app__flex p-text" key={`link-home`}>
          <div />
          <a href={`#home`}>Home</a>
        </li>
        <li className="app__flex p-text" key={`link-about`}>
          <div />
          <a href={`#about`}>About</a>
        </li>
        <li className="app__flex p-text" key={`link-shop`}>
          <div />
          <a href={`#shop`}>Art Collection</a>
        </li>
        <li className="app__flex p-text" key={`link-gallery`}>
          <div />
          <a href={`#gallery`}>Gallery</a>
        </li>
        <li className="app__flex p-text" key={`link-contact`}>
          <div />
          <a href={`#contact`}>Contact</a>
        </li>
      </ul>
      {windowWidth > 900 && (
        <div className="app__navbar-button">
          <a href="https://walterpaul-bebirian.pixels.com/art" target="_blank">
            <motion.button
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
              transition={{ duration: 0.3 }}
              className="header-shop-now-btn p-text"
            >
              Shop Now
            </motion.button>
          </a>
        </div>
      )}

      <div className="app__navbar-menu">
        <HiMenuAlt4 onClick={handleMenuToggle} />

        {toggle && (
          <div
            className="menu-content"
          >
            <></>
            <HiX onClick={handleMenuToggle} />
            <ul>
              <li className="app__flex p-text" key={`link-home`}>
                <div />
                <a href={`/`}>Home</a>
              </li>
              <li className="app__flex p-text" key={`link-about`}>
                <div />
                <a href={`/about`}>About</a>
              </li>
              <li className="app__flex p-text" key={`link-shop`}>
                <div />
                <a href={`/shop`}>Art Collection</a>
              </li>
              <li className="app__flex p-text" key={`link-gallery`}>
                <div />
                <a href={`/gallery`}>Gallery</a>
              </li>
              <li className="app__flex p-text" key={`link-contact`}>
                <div />
                <a href={`/contact`}>Contact</a>
              </li>
              <li className="app__flex p-text" key={`link-contact`}>
                <div />
                <a href="https://walterpaul-bebirian.pixels.com/art" target="_blank">
                  Shop Now
                </a>
              </li>
            </ul>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
