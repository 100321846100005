import React from 'react';
import { AppWrap } from '../../wrapper';
import './Header.scss';

const Header = () => (
  <>
    <div className="app__header app__flex">
      <h1 className="app__header-title">Walter Paul Bebirian</h1>
      <a
        target="_blank"
        href="https://walterpaul-bebirian.pixels.com/art"
        style={{ textDecoration: 'none' }}
      >
        <button className="app__header-button" type="button">
          <span>Shop Now</span>
        </button>
      </a>
    </div>
  </>
);

export default AppWrap(Header, 'home', 'home', 'app__primarybg');
